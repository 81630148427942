@import url('https://fonts.googleapis.com/css?family=Benne');
@import url('https://fonts.googleapis.com/css?family=Parisienne');
@import url('https://fonts.googleapis.com/css?family=Herr+Von+Muellerhoff');

.App {
  text-align: center;
  box-sizing: border-box;
}

h3 {
  font-family: "Benne", serif;
  font-size: 24px;
  line-height: 24px;
}

h2 {
  font-family: "Benne", serif;
  font-size: 60px;
}

hr {
  width: 90%;
}

p {
  font-family: Georgia, Serif, serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
}